import { TRANSPARENT } from "$lib/constants";
import type { DiscordUser, Guild, GuildMember } from "tupperschema";

export function getUserAvatarURL(user: DiscordUser) {
  if (user.avatar) {
    return `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`;
  } else {
    let index: number;
    if (user.discriminator == "0") {
      //index = BigInt ? Number(BigInt(user.id) % 6n) : 0;
      index = 0;
    } else {
      index = Number(user.discriminator) % 5;
    }
    return `https://cdn.discordapp.com/embed/avatars/${index}.png`;
  }
}

export function getMemberAvatarURL(guildID: string, member: GuildMember) {
  if (member.avatar) {
    return `https://cdn.discordapp.com/guilds/${guildID}/users/${member.user.id}/avatars/${member.avatar}.png`;
  } else return getUserAvatarURL(member.user);
}

export function getGuildIconURL(guild: Pick<Guild, "id" | "icon">) {
  return guild.icon
    ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`
    : TRANSPARENT
}